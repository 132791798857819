<template>
  <v-container>

    <div :class="sectionOne">
      <v-row align="start" >
        <v-col cols="12" lg="5" v-if="$vuetify.breakpoint.mdAndUp">
          <span class="title-page">Cajero inteligente para la recepción de pagos</span><br>
          <h3 id="section1" class="text-description">Empieza hoy con el cobro automático y acelera el tiempo de cada trámite en tus oficinas</h3><br>
          <v-btn rounded color="success" href="#contacto">Conoce más</v-btn>
        </v-col>
        <v-col cols="12" lg="5" v-if="$vuetify.breakpoint.smAndDown">
          <span class="title-page-movil">Cajero inteligente para la recepción de pagos</span><br>
          <h3 id="section1" class="text-description">Empieza hoy con el cobro automático y acelera el tiempo de cada trámite en tus oficinas</h3><br>
          <v-btn rounded color="success" href="#contacto">Conoce más</v-btn>
        </v-col>
        <v-col justify="center" cols="12" lg="7">
          <img class="img-principal" src="imgs/cajero2.jpg" width="70%"/>
        </v-col>
      </v-row><br>

    </div>
    <div class="section-one">
      <v-divider></v-divider><br>

      <v-row justify="center" class="section-description">
        <span class="text-slogan text-center">"En SIPREM® tenemos lo mejor en tecnología creada por el ser humano a disposición de los gobiernos municipales para mejorar la calidad de vida de la gente"</span>
      </v-row><br>

      <v-divider></v-divider><br>
    </div>
    <div :class="sectionOne">

      <div class="title-beneficios" v-intersect="{ handler: onShowTitleBeneficios, options: { threshold: 1.0 } }">
        <transition name="slide-vertical">
          <v-row align="center" justify="center" v-show="showTitleBeneficios">
            <p class="text-subtitles">Beneficios</p>
          </v-row>
        </transition>
      </div>

      <v-row class="row-margin" justify="space-between">
        <v-col justify="start" lg="5" cols="12">

          <div class="col-beneficio" v-intersect="{ handler: onShowBeneficio1, options: { threshold: 1.0 } }">
            <transition name="slide-fade">
              <v-row justify="center" v-show="showBeneficio1">
                <v-card :width="widthCards" style="min-height:120px;" shaped elevation="14">
                  <v-row justify="space-between">
                    <v-col cols="3" justify="center" align="center">
                      <div :class="classContainerIcon">
                        <img width="55px;" :style="styleIcon1" src="icons/cronometro_white.png"/>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <strong class="title-card">Rápido</strong><br>
                      <p class="text-card">Todo el proceso de cobro y facturación en segundos.</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </transition>
          </div><br v-if="$vuetify.breakpoint.mdAndDown">

          <div class="col-beneficio" v-intersect="{ handler: onShowBeneficio2, options: { threshold: 1.0 } }">
            <transition name="slide-fade">
              <v-row justify="center" v-show="showBeneficio2">
                <v-card :width="widthCards" style="min-height:120px;" shaped elevation="14">
                  <v-row justify="space-around">
                    <v-col cols="3" justify="center" align="center">
                      <div :class="classContainerIcon">
                        <img width="55px;" :style="styleIcon1" src="icons/24-7_white.png"/>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <strong class="title-card">Disponible</strong><br>
                      <p class="text-card">Recibe pagos todos los días a cualquier hora.</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </transition>
          </div>

          <div class="col-beneficio" v-intersect="{ handler: onShowBeneficio3, options: { threshold: 1.0 } }">
            <transition name="slide-fade">
              <v-row justify="center" v-show="showBeneficio3">
                <v-card :width="widthCards" style="min-height:120px;" shaped elevation="14">
                  <v-row justify="space-around">
                    <v-col cols="3" justify="center" align="center">
                      <div :class="classContainerIcon">
                        <img width="55px;" :style="styleIcon1" src="icons/signo_pesos_white.png"/>
                      </div>
                    </v-col>
                    <v-col cols="9">
                      <strong class="title-card">Ahorro</strong><br>
                      <p class="text-card">Retorno de inversión en menos de 1 año. Garantizado.</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
            </transition>
          </div>

        </v-col>
        
        
        <v-col lg="5" cols="12" justify="center" align="center" height="100%">
          <v-card style="border-radius: 15px; box-shadow: 5px 7px 7px 5px #7C7C7C;" width="100%" color="#003E60" align="center" :class="cards">
            <v-carousel cycle height="100%" hide-delimiters show-arrows-on-hover>
              <v-carousel-item align="center">
                <img src="imgs/efectivo.jpg" width="100%" height="100%"/>
                <center class="text-carousel txt-supposition">Billetes y Monedas</center>
              </v-carousel-item>
              <v-carousel-item align="center">
                <img src="imgs/tpv.jpg" width="100%" height="100%"/>
                <center class="text-carousel txt-supposition">Terminal Bancaria</center>
              </v-carousel-item>
              <v-carousel-item align="center">
                <img src="imgs/remoto.jpg" width="100%" height="100%"/>
                <center class="text-carousel txt-supposition">Monitoreo remoto</center>
              </v-carousel-item>
              <v-carousel-item align="center">
                <img src="imgs/touch.jpg" width="100%" height="100%"/>
                <center class="text-carousel txt-supposition">Sistema táctil</center>
              </v-carousel-item>
            </v-carousel>
            
          </v-card>
        </v-col>
        

      </v-row>
    </div>

    <div class="section-two">

    <v-divider></v-divider><br>

    <div class="title-caracteristicas" v-intersect="{ handler: onShowTitleCaracteristicas, options: { threshold: 1.0 } }">
      <transition name="slide-vertical">
        <v-row align="center" justify="center" v-show="showTitleCaracteristicas">
          <p class="text-subtitles">Características</p>
        </v-row>
      </transition>
    </div>

    <v-row class="row-margin">
        
      <v-col :class="classColCaracteriscticas" lg="4" cols="12" v-intersect="{ handler: onShowCaracteristica1, options: { threshold: 1.0 } }">
        <transition name="slide-fade">
          <v-row justify="center" v-show="showCaracteristica1">
            <v-card shaped max-width="300" elevation="14">
              <v-row align="center" justify="center">
                <div class="img-supposition-rounded-2">
                  <div class="img-supposition-ball-2"> 
                    <img width="55px;" style="margin-top:33px;" src="icons/money_white.png"/>
                  </div>
                </div>
              </v-row>
              <v-row justify="center" class="margin-sup-card">
                <strong class="title-card">Recepción de Efectivo</strong>
              </v-row>
              <div class="text-center text-content text-padding">
                Recibe todas las denominaciones en billetes y monedas. Reutiliza automáticamente el efectivo ingresado para utilizarlo en cambio.
              </div>
            </v-card>
          </v-row>
        </transition>
      </v-col>

      <v-col :class="classColCaracteriscticas" lg="4" cols="12" v-intersect="{ handler: onShowCaracteristica2, options: { threshold: 1.0 } }">
        <transition name="slide-fade">
          <v-row justify="center" v-show="showCaracteristica2">
            <v-card shaped max-width="300" elevation="14">
              <v-row align="center" justify="center">
                <div class="img-supposition-rounded-2">
                  <div class="img-supposition-ball-2"> 
                    <img width="45px;" style="margin-top:27px;" src="icons/sat_white.png"/>
                  </div>
                </div>
              </v-row>
              <v-row justify="center" class="margin-sup-card">
                <strong class="title-card">Factura Electrónica</strong>
              </v-row>
              <div class="text-center text-content text-padding">
                Genera la factura electrónica por cada cobro realizado y la manda vía correo de forma automática y al instante.
              </div>
            </v-card>
          </v-row>
        </transition>
      </v-col>

      <v-col :class="classColCaracteriscticas" lg="4" cols="12" v-intersect="{ handler: onShowCaracteristica3, options: { threshold: 1.0 } }">
        <transition name="slide-fade">
          <v-row justify="center" v-show="showCaracteristica3">
            <v-card shaped max-width="300" elevation="14">
              <v-row align="center" justify="center" class="margin-sup-card">
                <div class="img-supposition-rounded-2">
                  <div class="img-supposition-ball-2"> 
                    <img width="45px;" style="margin-top:28px; margin-left:-5px;" src="icons/engranes_white.png"/>
                  </div>
                </div>
              </v-row>
              <v-row justify="center">
                <strong class="title-card">Integración Total</strong>
              </v-row>
              <div class="text-center text-content text-padding">
                Único sistema en México para la recepción de todos los conceptos de cobro manejados por los gobiernos municipales del país.
              </div>
            </v-card>
          </v-row>
        </transition>
      </v-col>

    </v-row><br>

    </div>
  </v-container>
</template>

<script scoped>
  export default {
    computed: {
      classColCaracteriscticas: function(){
        return this.$vuetify.breakpoint.lgAndUp ? 'col-caracteristicas' : 'col-caracteristicas-sm';
      },
      styleIcon1: function(){
        return this.$vuetify.breakpoint.lgAndUp ? 'margin-top:15px;' : 'margin-top:7px;';
      },
      classContainerIcon: function(){
        return this.$vuetify.breakpoint.lgAndUp ? 'img-supposition-squad' : 'img-supposition-squad-sm';
      },
      widthCards: function(){
        return this.$vuetify.breakpoint.lgAndUp ? '100%' : '80%';
      },
      cards: function() {
        return this.$vuetify.breakpoint.xlOnly ? 'cards-xl' : 'cards-lgAnDown';
      },
      sectionOne: function(){
        return this.$vuetify.breakpoint.xlOnly ? 'section-one-xl' : 'section-one-lgAndDown';
      }
    },
    data() {
      return {
        iconSize: "70px",
        showTitleBeneficios: false,
        showBeneficio1: false,
        showBeneficio2: false,
        showBeneficio3: false,
        showTitleCaracteristicas: false,
        showCaracteristica1: false,
        showCaracteristica2: false,
        showCaracteristica3: false
      }
    },
    created () {
      window.scrollTo(0, 0);
    },
    methods: {
      onShowCaracteristica3(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "3" del sistema esta en pantalla */
        this.showCaracteristica3 = entries[0].isIntersecting;
      },
      onShowCaracteristica2(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "2" del sistema esta en pantalla */
        this.showCaracteristica2 = entries[0].isIntersecting;
      },
      onShowCaracteristica1(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "1" del sistema esta en pantalla */
        this.showCaracteristica1 = entries[0].isIntersecting;
      },
      onShowTitleCaracteristicas(entries){/* Se manda a llamar cuando el titulo características esta en pantalla */
        this.showTitleCaracteristicas = entries[0].isIntersecting;
      },
      onShowBeneficio3(entries){/* Se manda a llamar cuando el div que contiene la información del beneficio "3" del sistema esta en pantalla */
        this.showBeneficio3 = entries[0].isIntersecting;
      },
      onShowBeneficio2(entries){/* Se manda a llamar cuando el div que contiene la información del beneficio "2" del sistema esta en pantalla */
        this.showBeneficio2 = entries[0].isIntersecting;
      },
      onShowBeneficio1(entries){/* Se manda a llamar cuando el div que contiene la información del beneficio "1" del sistema esta en pantalla */
        this.showBeneficio1 = entries[0].isIntersecting;
      },
      onShowTitleBeneficios(entries){/* Se manda a llamar cuando el titulo "Beneficios" esta en pantalla */
        this.showTitleBeneficios = entries[0].isIntersecting;
      }
    }
  }
</script>
<style>
.img-supposition-squad{
  background: linear-gradient(90deg, rgba(28,113,173,1) 0%, rgba(16,81,149,1) 60%, rgba(4,71,123,1) 80%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 8px;
  width: 25%;
  height: 90px;
  position:absolute;
  left: -25px;
  top:13%;
  text-align:center;
}
.img-supposition-squad-sm{
  background: linear-gradient(90deg, rgba(28,113,173,1) 0%, rgba(16,81,149,1) 60%, rgba(4,71,123,1) 80%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 8px;
  width: 25%;
  height: 70px;
  position:absolute;
  left: -25px;
  top:25%;
  text-align:center;
}
.margin-sup-card{
  margin-top:80px;
}
</style>